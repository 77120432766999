<template>
  <div class="relative mb-24">
    <div class="grid grid-cols-1 items-center">
      <div
        class="w-full flex flex-cols text-white lg:text-[64px] text-[40px] lg:justify-start justify-center font-medium font-['Montserrat'] capitalize lg:leading-[89px] leading-[50px]"
      >
        Services we offer
      </div>
    </div>
    <CommonHorizontalCarousel :items="services" :items-to-show="itemsToShow" :auto-play="2000" c-slide-classes="p-3">
      <template #default="{ item }">
        <div class="opacity-95 bg-stone-800 rounded-[10px] p-5">
          <div class="flex flex-col w-full">
            <div class="flex items-center justify-center text-center rounded-3xl w-full h-full md:h-96 ">
              <img v-lazy-load  :data-src="item.image" class="max-h-56 object-contain w-full h-full md:max-h-max md:object-cover rounded-3xl">
            </div>
          </div>
          <div class="flex flex-col w-full mt-5 p-5 text-left">
            <div class="w-full text-white lg:text-3xl text-2xl font-medium font-['Montserrat'] leading-[30px]">
              {{ item.title }}
            </div>
            <div class="w-full text-white lg:text-xl text-lg font-normal font-['Montserrat'] lg:leading-[25px] leading-7 mt-3">
              {{ item.description }}
            </div>
          </div>
        </div>
      </template>
    </CommonHorizontalCarousel>
  </div>
</template>

<script setup lang="ts">
const services = [
  {
    title: 'Metal Roof Replacement',
    description: 'Canopy Roofing specializes in evaluating, installing, and maintaining durable metal roofing systems. Update your home with a metal roof to enhance its value and potentially see savings on energy costs and insurance premiums.',
    image: '/services/wind-damage-service.webp'
  },
  {
    title: 'Cedar Shake Roof Replacement',
    description: 'You can trust Canopy to professionally install and maintain your cedar shake roof, whether you are using traditional wood or the new, highly durable and attractive synthetics, Canopy can help.',
    image: '/services/roof-replacement.webp'
  },
  {
    title: 'Storm Damage Inspection',
    description: 'Following a severe weather event, it’s essential to have your roof inspected for any signs of damage. Sometimes storm damage to the roof isn’t easy to see but it’s important to catch as early as possible to minimize damage. Our team of experienced professionals will assess the extent of the damage and identify areas that require immediate attention.',
    image: '/services/storm-damage-inspection-service.webp'
  },
  {
    title: 'Hail Damage Services',
    description: 'Hailstorms can cause extensive damage to your roof, from cracked shingles to punctures and dents. Our hail damage restoration services are designed to address these issues efficiently and effectively. We’ll assess the damage, work to assist you with your insurance company to file a claim if needed, and complete custom roofing solutions using best-in-class metal and synthetic materials and expert craftsmanship.',
    image: '/services/hail-damage-service.webp'
  },
  {
    title: 'Wind Damage Services',
    description: 'High winds can loosen or dislodge shingles, tear off flashing, and compromise the structural integrity of your roof. We’ll address these issues, ensuring that your roof is secure and weather-resistant.',
    image: '/services/wind-damage-service.webp'
  },
  {
    title: 'Roof Replacement',
    description: 'Professional replacement services to upgrade your roof and enhance your home\'s curb appeal.',
    image: '/services/roof-replacement.webp'
  },
  {
    title: 'Multi-Unit Roofing & Repair',
    description: 'Multi-Unit insurance & installation experts, with Multi-Unit discounts available.',
    image: '/services/multi-unit-roofing-service.webp'
  }
]
const { width } = useWindowSize()
const itemsToShow = computed(() => (width.value >= 768 ? width.value >= 1024 ? 3 : 2 : 1))
</script>
